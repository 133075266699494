h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: $headings-margin-bottom;
  margin-top: 0;
  font-weight: $headings-font-weight700;
  line-height: $headings-line-height;
  color: $headings-color;
}

h1,
.h1 {
  font-size: $font-size-h1;
}
h2,
.h2 {
  font-size: $font-size-h2;
}
h3,
.h3 {
  font-size: $font-size-h3;
}
h4,
.h4 {
  font-size: $font-size-h4;
}
h5,
.h5 {
  font-size: $font-size-h5;
}
h6,
.h6 {
  font-size: $font-size-h6;
}

a,
a:focus,
a:hover {
  cursor: pointer;
  text-decoration: none;
  color: inherit;
  outline: 0;
}

button {
  outline: 0;
}

figure {
  margin: 0;
}

strong,
b {
  font-weight: 700;
}

.mat-card-title {
  font: {
    size: 18px;
    weight: 500;
  }
}

.strikethrough {
  text-decoration: line-through;
}
.text-uppercase {
  text-transform: uppercase;
}
.text-capitalize {
  text-transform: capitalize;
}


@for $i from 10 through 78 {
  .text-#{$i} {
    font-size: #{$i}px;
    &.mat-icon {
      width: #{$i}px !important;
      height: #{$i}px !important;
      line-height: #{$i}px !important;
    }
  }
}

.font-weight-light {
  font-weight: 300;
}
.font-weight-normal {
  font-weight: 400;
}
.font-weight-bold {
  font-weight: bold;
}
.font-weight-inherit {
  font-weight: inherit;
}

.line-height-1 {
  line-height: 1 !important;
}
.mat-heading-label {
  font-weight: 600;
  font-size: $font-size-h6;
  letter-spacing: .92px;
  text-transform: uppercase;
  margin: 0;
  padding-bottom: 1rem;
}

@mixin typography-color($theme) {
  $foreground: map-get($theme, foreground);
  .text-muted, .mat-heading-label {
    color: map-get($foreground, 'secondary-text')
  }
}