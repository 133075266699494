/* You can add global styles to this file, and also import other style files */
@import "../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";
@import "../node_modules/moment-timezone-picker/assets/moment-timezone-picker.theme";
@font-face {
    font-family: 'poppins-medium';
    src: url('./assets/fonts/poppins/Poppins-Medium.woff') format('woff2'),
        url('./assets/fonts/poppins/Poppins-Medium.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'poppins';
    src: url('./assets/fonts/poppins/Poppins-Regular.woff') format('woff2'),
        url('./assets/fonts/poppins/Poppins-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'poppins-bold';
    src: url('./assets/fonts/poppins/Poppins-SemiBold.woff') format('woff2'),
        url('./assets/fonts/poppins/Poppins-SemiBold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

body,.mat-option-text{
      font-family: 'poppins' !important;
  }
  
.requiredfield:after {
    content:" *";
    color:red;
    font-family: poppins;
}

.mat-form-field-appearance-outline .mat-form-field-wrapper{
    margin-bottom: -15px !important;
}

.sign-out{
    border-radius: 5px;
}

.mat-card{
    box-shadow:none !important;
}

.mat-card:hover{
    box-shadow: 0 8px 12px rgb(47 60 74 / 4%), 0 2px 6px rgb(47 60 74 / 8%) !important;
}

.tm-mango-mentors .mat-raised-button{
    box-shadow:none !important;
}
.page-title{
    color: #31384F;
    font-family: poppins;
    font-weight: 600;
    font-size: 25px;
    display: inline-block;
    margin-right: 10px;
}
.row{
    margin-right: 0px !important;
    margin-left: 0px !important;
}
.ag-theme-alpine .ag-row {
    font-family: poppins;
    font-size: 16px;
}
.ag-overlay-no-rows-wrapper {
    font-family: 'poppins-bold';
    font-size: 24px;
    font-weight: 600;
    color: #31384F;
}

.tm-mango-mentors .mat-menu-item{
    font-family: 'poppins-bold';
    font-weight: 600;
}
.signout{
    width: 100% !important;
    height: 8vh !important;
    cursor: pointer;
}
.signout-height{
    height: calc(100vh - 410px)
}

.signout-footer{
    font-family: 'poppins-bold';
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
    color: #838795;
    text-align: center;
    padding-bottom:15px;
}

.filter{
    width: 20% !important;
    height: 5vh !important;
    margin-top: 10px;
}

.width100{
    width: 100% !important;
}

.cursor-pointer{
    cursor: pointer;
}

.confirm-close{
    font-size: 21px;
    vertical-align: middle;
    margin-right: 10px;
    cursor: pointer;
}
.mat-slide-toggle{
    opacity: 1 !important;
}

.dialog-header, .no-records{
    font-family: 'poppins-bold';
    font-size: 24px;
    font-weight: 600;
    color: #31384F;
}

.cancel-bg{
    background-color: #E2E3E4 !important;
    color: #31384F;
    margin-right: 10px !important;
}

.mat-button, .mat-raised-button{
    font-family: 'poppins' !important;
    font-weight: 500 !important;
    font-size: 16px !important;
}

.menu-change-icon{
    height: 50px !important;
    width: 50px !important;
    // margin-left: 210px;
    position: absolute;
}

.menu-icon-container{
    height: 100% !important;
    width: 100% !important;
}

.logout-icon{
    width: 100% !important;
    height: 83% !important;
}

.height100{
    height: 100vh;
    width: 100%;
}
.searchEvent .mat-form-field-wrapper{
    margin: 0 !important;
    height: 43px !important;
    .mat-form-field-flex{
        position: initial !important;
    }
}
.searchEvent .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-outline{
	background-color: white;
}
.searchEvent .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix{
	padding: 0.25em 0 1em 0 !important;
}

.iconBorder{
    border-right: solid 1px #d6d6d6;
    margin-right: 5px;
    margin-left: 5px;
}

.clear-bg{
    background-color: #FFE9E8 !important;
    color: #F44841 !important;
}

.mat-raised-button {
    height: 50px;
    border-radius: 16px !important;
}

.mat-dialog-container{
    border-radius: 16px !important;
}

.tm-mango-mentors .mat-input-element{
    font-size: 16px;
    font-family: poppins-medium;
}

.mat-form-field-outline{
    background-color: #ffffff;
}

.cdk-overlay-dark-backdrop{
    opacity: 1;
    backdrop-filter: blur(4px);
}

.cdk-overlay-dark-backdrop {
    background: rgb(165 158 158 / 7%);
}

.angular-editor-textarea{
    min-height: 60vh !important;
}

.overlay-popout-google{
    right: 27px !important;
}