// General
$transitionDuration: 300ms !default;
$gutter: 1rem !default;
$transitionTiming: cubic-bezier(.35,0,.25,1);
$sidebarTrnTime: .3s;

// Widths
$sidebar-width: 15rem !default;
$compact-sidebar-width: 64px !default;
$compact-big-sidebar-width: 150px !default;

$sidebar-navigation-icon-size: 16px;

// Typography
$font-family-base: Arial !default;
$font-size-base: .875rem !default;
$font-weight-base: 400 !default;
$font-weight-medium: 500 !default;
$font-weight-bold: 600 !default;

$font-size-h1: 24px !default;
$font-size-h2: 20px !default;
$font-size-h3: 16px !default;
$font-size-h4: 15px !default;
$font-size-h5: 14px !default;
$font-size-h6: 13px !default;

$headings-margin-bottom: (1rem * 0.5) !default;
$headings-font-family:   inherit !default;
$headings-font-weight:   500 !default;
$headings-font-weight700:   700 !default;
$headings-line-height:   1.1 !default;
$headings-color:         inherit !default;


// Colors
$body-color: #304156;
$success-green: #51a351;
$white: #ffffff;
$warning-yellow: #f89406;
$info-blue: #2f96b4;
$error-red: #bd362f;
$primary: #0269F1;
$accent: #711E82;
$grey-84: #D6D6D6;
$icon-red: #FF0000;
$dark-gray50: #9B9B9B;

// Shadow
$shadow-primary: 0 8px 12px rgba(47, 60, 74, 0.04), 0 2px 6px rgba(47, 60, 74, 0.08);
/*
snackBar css
*/
.success {
  background-color: $success-green !important;
  color: $white !important;
}
.warning{
  background-color: $warning-yellow !important;
  color: $white !important;
}
.info {
  background-color: $info-blue !important;
  color: $white !important;
}
.error{
  background-color: $error-red !important;
  color: $white !important;
}
