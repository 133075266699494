html {
  font-size: 16px;
}

html,
body {
  width: 100%;
  // height: 100%;
  position: relative;
  // overflow: hidden;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-touch-callout: none;
  // min-height: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  padding: 0;
  font-weight: $font-weight-base;
  font-size: $font-size-base;
  line-height: 1.5;
  font-family: $font-family-base;
}

[tabindex='-1']:focus {
  outline: none;
}

select,
button,
textarea,
input {
  vertical-align: baseline;
}
div {
  box-sizing: border-box;
}
html,
body {
  &[dir='rtl'],
  &[dir='ltr'] {
    unicode-bidi: embed;
  }
}
bdo[dir='rtl'] {
  direction: rtl;
  unicode-bidi: bidi-override;
}
bdo[dir='ltr'] {
  direction: ltr;
  unicode-bidi: bidi-override;
}

.mat-card {
  font-size: 0.875rem;
}
img:not(.mat-card-image) {
  max-width: 100%;
}
a,
a:focus,
a:hover {
  text-decoration: none;
}
a {
  color: inherit;
}
p {
  margin: 0 0 16px;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0.5rem;
  font-weight: 400;
  line-height: 1.1;
  color: inherit;
}
.h1,
h1 {
  font-size: 2rem;
}
.h2,
h2 {
  font-size: 1.75rem;
}
.h3,
h3 {
  font-size: 1.5rem;
}
.h4,
h4 {
  font-size: 1.25rem;
}
.h5,
h5 {
  font-size: 1rem;
}
.h6,
h6 {
  font-size: 0.875rem;
}
code {
  padding: 8px;
  background: rgba(0, 0, 0, 0.08);
}
/*---- Common -----*/
.container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 15px;
}
.bg-none {
  background: transparent !important;
}
.bg-white {
  background: #ffffff !important;
}

.h-full {
  min-height: 100vh;
}
.fix {
  position: relative;
  overflow: hidden;
}
.fix-elm::after {
  display: table;
  width: 100%;
  content: '';
}
.mat-box-shadow {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.light-gray {
  background: rgba(0, 0, 0, 0.024);
}
.light-mat-gray {
  background: rgba(0, 0, 0, 0.08);
}
.mat-blue {
  background: #247ba0;
  color: #fefefe;
}
.mat-red {
  background-color: #f44336 !important;
  color: #fff !important;
}
.mat-indigo {
  background-color: #3f51b5 !important;
  color: #fff !important;
}
.mat-brown {
  background-color: #785548 !important;
  color: #fefefe;
}
.mat-teal {
  background-color: #009688 !important;
  color: #fff !important;
}
.mat-purple {
  background-color: #9c27b0 !important;
  color: hsla(0, 0%, 100%, 0.87) !important;
}
.fz-1 {
  font-size: 1rem !important;
}
.fz-2 {
  font-size: 2rem !important;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.font-light {
  font-weight: 300 !important;
}
.font-normal {
  font-weight: normal !important;
}
.fw-300 {
  font-weight: 300 !important;
}
.fw-400 {
  font-weight: 400 !important;
}
.fw-500 {
  font-weight: 500 !important;
}
.fw-600 {
  font-weight: 600 !important;
}
.fw-700 {
  font-weight: 700 !important;
}
.fw-800 {
  font-weight: 800 !important;
}
.fw-900 {
  font-weight: 900 !important;
}

.text-muted-white {
  color: rgba(255, 255, 255, 0.54) !important;
}
.text-gray {
  color: rgba(0, 0, 0, 0.7) !important;
}
.text-sm {
  font-size: 0.813rem;
}
.list-item-active {
  border-left: 3px solid;
}
.material-icons.icon-sm {
  font-size: 18px !important;
  line-height: 18px !important;
  height: 18px;
  width: 18px;
}
.material-icons.icon-xs {
  font-size: 13px !important;
  line-height: 13px;
  height: 13px;
  width: 13px;
}

.mat-button,
.mat-raised-button {
  font-family: Arial !important;
  font-weight: bold !important;
  letter-spacing: 1px;
}
[mat-lg-button] {
  padding: 0 32px !important;
  font-size: 18px;
  line-height: 56px !important;
}
.mat-icon-button[mat-sm-button] {
  height: 24px;
  width: 24px;
  line-height: 24px;
}

.mat-icon-button[mat-xs-button] {
  height: 20px;
  width: 20px;
  line-height: 20px;
}
.mat-icon-button[mat-xs-button] .mat-icon {
  font-size: 16px;
  line-height: 20px !important;
  height: 20px;
  width: 20px;
}
.mat-chip[mat-sm-chip] {
  padding: 4px 6px 4px 6px !important;
  border-radius: 4px !important;
  font-size: 12px !important;
  line-height: 12px !important;
  max-height: 20px;
  box-sizing: border-box;
}
.mat-icon-button.img-button img {
    border-radius: 5px;
    object-fit: cover;
    margin-top: -5px;
}
.compact-list .mat-list-item {
  line-height: 1.1;
  transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.compact-list .mat-list-item:hover {
  background: rgba(0, 0, 0, 0.08);
  cursor: pointer;
}
.list-tasktype .tasktype-item {
  padding: 12px;
}
.list-tasktype .tasktype-item:hover {
  background: rgba(0, 0, 0, 0.08);
}
.list-tasktype .tasktype-item .tasktype-action {
  visibility: hidden;
}
.list-tasktype .tasktype-item:hover .tasktype-action {
  visibility: visible;
}
.doughnut-grid {
  border-radius: 2px;
  overflow: hidden;
}
.doughnut-grid .doughnut-grid-item {
  padding: 1rem;
}
.doughnut-grid .doughnut-grid-item p {
  margin: 0;
}
.doughnut-grid .doughnut-grid-item .chart {
  margin: 0 0 8px;
}

.logo-group {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.toolbar-avatar {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
  line-height: 24px;
}
.toolbar-avatar > img {
  width: 40px !important;
  border-radius: 50%;
}
.toolbar-avatar.md,
.toolbar-avatar.md > img {
  width: 48px !important;
  height: 48px;
}
.toolbar-avatar > .status-dot {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  top: 25px;
  right: -2px;
  border: 2px solid #ffffff;
  background: #ccc;
}
.full-width {
  width: 100% !important;
}
.dnd-item {
  cursor: move;
  cursor: grab;
  cursor: -webkit-grab;
}
.icon-circle {
  color: rgb(255, 255, 255);
  font-size: 1.2rem;
  text-align: center;
  line-height: 1.6rem;
  border-radius: 50%;
}
.mat-sidenav .mat-list-item:hover {
  background: rgba(0, 0, 0, 0.035) !important;
}
.mat-chip {
  position: relative;
  overflow: hidden;
}
.text-small {
  font-size: 0.813rem;
}
.text-small .mat-icon {
  font-size: 1rem;
  vertical-align: sub;
  margin: 0 2px;
}
// .mat-card-title {
//   font-size: 1rem !important;
// }
.icon-chip {
  font-size: 11px !important;
  padding: 4px 8px !important;
  display: flex !important;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.icon-chip .mat-icon {
  font-size: 16px;
  height: 16px;
  width: 16px;
  margin-right: 4px;
}
.mat-chip:not(.mat-basic-chip) {
  display: inline-block;
  padding: 8px 12px 8px 12px;
  border-radius: 24px;
  font-size: 13px;
  line-height: 16px;
}
.ql-container .ql-editor {
  min-height: 200px;
}
.chart {
  display: block;
  width: 100%;
}
.form-error-msg {
  color: #f44336;
  display: block;
  padding: 5px 0;
}
.accordion-handle {
  cursor: pointer;
}
.app-accordion {
  max-height: 200px;
  transition: max-height 0.3s ease;
}
.app-accordion.open {
  max-height: 1000px;
}
.app-accordion .accordion-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
}
.app-accordion.open .accordion-content {
  max-height: 800px;
}
.app-accordion.open .hidden-on-open {
  display: none !important;
}
.app-accordion:not(.open) .show-on-open {
  display: none !important;
}
mat-list a[mat-list-item] .mat-list-item-content,
mat-list mat-list-item .mat-list-item-content,
mat-nav-list a[mat-list-item] .mat-list-item-content,
mat-nav-list mat-list-item .mat-list-item-content,
[mat-menu-item],
.mat-option,
body .mat-select-trigger {
  font-size: 0.875rem !important;
}
.mat-ripple {
  position: relative;
}
.fileupload-drop-zone {
  text-align: center;
  border: 1px dashed rgba(0, 0, 0, 0.15);
  padding: 48px;
  height: 120px;
}
.default-table {
  text-align: left;
}
.default-table > thead tr th {
  font-weight: 400;
  padding: 0.9rem 1.2rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.default-table tbody tr td {
  padding: 0.9rem 1.2rem;
}
.app-error {
  text-align: center;
  width: 320px;
  max-width: 320px;
  margin: 0 auto;
}
.app-error .error-icon {
  height: 120px;
  width: 120px;
  font-size: 120px;
  float: left;
}
.app-error .error-text {
  float: right;
  width: 200px;
  max-width: 200px;
}
.app-error .error-title {
  font-size: 5rem;
  font-weight: 900;
  margin: 0;
}
.app-error .error-subtitle {
  font-size: 1.5rem;
  font-weight: 300;
}
.app-error .error-actions {
  width: 100%;
  overflow: hidden;
  min-height: 54px;
  margin-top: 100px;
}

/*---- Loader ----*/
.app-loader,
.view-loader {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
}
.spinner {
  width: 40px;
  height: 40px;
  position: relative;
  margin: auto;
}
.double-bounce1,
.double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;
}
.double-bounce2 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}
.view-loader {
  display: block;
  padding-top: 160px;
  background: rgba(255, 255, 255, 0.3);
  z-index: 9999;
}
.view-loader * {
  margin: auto;
}
@-webkit-keyframes sk-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
  }
}
@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

/*---- Third pirty adjust -----*/

/*------- quill rich text editor ----------*/
.p-0.mat-card-content .ql-container {
  border: 0 !important;
}
.p-0.mat-card-content .ql-toolbar.ql-snow {
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
/*--- Scroll Bar ---*/
.ps__scrollbar-y-rail {
  z-index: 999;
}
.collapsed-menu .ps__scrollbar-y-rail {
  z-index: auto;
}

/*--- Data table ---*/
.ngx-datatable.material {
  box-shadow: $shadow-primary !important;
}
.ngx-datatable.material .datatable-header .datatable-header-cell {
  background-color: inherit !important;
}
.datatable-body-cell {
  display: inline-flex !important;
  align-items: center;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.datatable-body-cell-label {
  width: 100%;
  display: flex;
}
/*------ Map ------*/
.agm-info-window-content {
  color: rgba(0, 0, 0, 0.87);
}
/*-------- Chart js ---------*/
.chart {
  margin-left: -10px;
}


.ps__rail-y {
  right: 0 !important;
  left: auto !important;
}

[dir='rtl'] {
  .ps__rail-y {
    right: auto !important;
    left: 0 !important;
  }
  .ps__thumb-y {
    right: auto !important;
    left: 1px !important;
  }
  .ngx-datatable .datatable-footer .datatable-pager .pager {
    float: left;
  }
  .user-card .user-details .mat-icon {
    margin-right: 0;
    margin-left: 0.5rem;
  }
}

[dir='rtl'] {
  .search-bar-wide .search-close {
    top: 20px;
    right: auto !important;
    left: 15px;
  }
}

@media (max-width: 767px) {
  .mat-card-title .mat-divider {
    margin-left: 0;
    margin-right: 0;
  }
  .accordion-handle {
    flex-direction: column !important;
  }
  .app-error .error-icon {
    height: 100px;
    width: 100px;
    font-size: 100px;
  }
}
