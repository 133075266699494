.main-footer {
  padding: 0 0.667rem;
  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 64px;
  }
  .navigation-top & {
    margin: 0 -1rem -.333rem;
  }
}
